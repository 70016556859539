import React from 'react';
import Layout from '../components/Layout';
import { Helmet } from 'react-helmet-async';
import constants from '../constants';

export default () => {
  return (
    <Layout>
      <Helmet titleTemplate={`%s | ${constants.siteName}`}>
        <html lang="ja" />
        <title>404 not found</title>
        <meta name="description" content="404 not found" />
      </Helmet>
      <div>404 not found</div>
    </Layout>
  );
};
